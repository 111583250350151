import React, { useMemo } from 'react';
import ReactImageMagnify from 'react-image-magnify';
import { useMediaQuery } from 'react-responsive';
import classnames from 'classnames';
import { ProductFlags } from '@engbers/components';
import { convertImageToWebp, getOriginalSize, resizeImage } from '@frontastic-engbers/helpers/utils/meleven';
import { useIsMounted } from '@frontastic-engbers/helpers/hooks/useIsMounted';
import { useProductOutfitTitle } from '@frontastic-engbers/helpers/seoHelper';
import { Product } from '@frontastic-engbers/types/product/Product';
import { Variant } from '@frontastic-engbers/types/product/Variant';
import { Slider } from '@engbers/components/slider';
import { PinchZoomImage } from './pinch-zoom-image';
import { MediaType } from '@frontastic-engbers/types/engbers-custom';
import styles from './pdp-images.module.scss';
export interface IPdpImages {
  className?: string;
  product: Product;
  variant: Variant;
  isOutfit?: boolean;
  fallbackImage?: MediaType;
}
export const PdpImages: React.FC<IPdpImages> = ({
  className,
  product,
  variant,
  isOutfit = false,
  fallbackImage
}) => {
  const {
    images
  } = variant.images.length === 0 ? {
    images: [fallbackImage?.media?.file]
  } : variant;
  const attributes = {
    ...product.attributes,
    ...variant.attributes
  };
  const isMounted = useIsMounted();
  const isMobile = useMediaQuery({
    maxWidth: 1023
  });
  const innerWidth = typeof window !== 'undefined' ? window.innerWidth : 0;
  const {
    title
  } = useProductOutfitTitle({
    product,
    attributes,
    isOutfit
  });
  const outfitSliderConfig = isOutfit ? {
    fitToSlides: !isMobile,
    slideWidth: !isMobile && 405,
    slidesPerView: 1
  } : null;
  const width = useMemo(() => {
    const bodyWidth = typeof document !== 'undefined' ? document.body.clientWidth : 0;
    const outerPadding = 32;
    const minWidth = Math.min(1280 + outerPadding, bodyWidth) - outerPadding;
    const widthPercentage = innerWidth <= 1280 ? 0.3 : 0.25;
    return Math.round(minWidth * widthPercentage);
  }, [innerWidth]);
  return <div className={classnames(className, styles.pdpImages, {
    [styles.odpImages]: isOutfit
  })} data-sentry-component="PdpImages" data-sentry-source-file="index.tsx">
      {isMounted && isMobile && product.flags && <div className={styles.productImageFlags}>
          <ProductFlags flags={product.flags} flagOrder={['sale', 'new']} />
          <ProductFlags flags={product.flags} flagOrder={['campaign', 'engbersGermany']} />
        </div>}
      <Slider key={variant.id} {...outfitSliderConfig} loop={isOutfit ? images.length > 1 : images.length > 2} arrows={isOutfit ? images.length > 1 : images.length > 2} dots={false} spaceBetween={10} breakpoints={{
      0: {
        slidesPerView: 1
      },
      1025: {
        slidesPerView: isOutfit ? 1 : 2
      }
    }} thumbsOptions={{
      multipleActiveThumbs: false,
      autoScrollOffset: isOutfit ? 0 : 2
    }} thumbsSwiperProps={{
      spaceBetween: isOutfit ? 16 : 5,
      navigation: isOutfit,
      slidesPerView: isOutfit ? 3.5 : 'auto'
    }} withThumbs data-sentry-element="Slider" data-sentry-source-file="index.tsx">
        {images.map((url, i) => {
        const resizeWidth = isOutfit ? 405 : 560;
        const resizeHeight = isOutfit ? 541 : 747;
        const src = convertImageToWebp(resizeImage(url, resizeWidth, resizeHeight));
        if (!isMobile) {
          return isOutfit ? <ReactImageMagnify {...{
            smallImage: {
              alt: title,
              isFluidWidth: true,
              src
            },
            largeImage: {
              src: getOriginalSize(url),
              width: 1000,
              height: 1300
            },
            enlargedImagePosition: 'over'
          }} key={src} /> : <ReactImageMagnify {...{
            smallImage: {
              alt: title,
              isFluidWidth: true,
              src
            },
            largeImage: {
              src: getOriginalSize(url),
              width: 1200,
              height: 1600
            },
            enlargedImagePortalId: 'magnifyContainer',
            enlargedImageContainerDimensions: {
              width,
              height: '100%'
            },
            enlargedImageStyle: {
              maxWidth: 'initial'
            },
            shouldUsePositiveSpaceLens: true,
            lensStyle: {
              background: 'rgb(255, 255, 255, 0.4)',
              border: '1px solid black'
            }
          }} key={src} />;
        }
        return <PinchZoomImage key={`zoom-image--${i}`} src={src} title={title} />;
      })}
      </Slider>
    </div>;
};