import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useRecoilState } from 'recoil';
import { isCheckoutSubmittedState } from '@frontastic-engbers/lib/state/checkoutErrors/atoms';
import { useMediaQuery } from 'react-responsive';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { useIsMounted } from '@frontastic-engbers/helpers/hooks/useIsMounted';
import { getProjectInfo, useCart } from '@frontastic-engbers/lib';
import { useSnippets } from '@frontastic-engbers/lib/actions/config';
import getBlockStyle from '@frontastic-engbers/helpers/utils/getBlockStyle';
import { validatePostalCode } from '@frontastic-engbers/helpers/utils/validatePostalCode';
import { validateStreetName } from '@frontastic-engbers/helpers/utils/validateStreetName';
import { mapToCartStructure } from '../utils/mapFormData';
import { Address as AddressType } from '@frontastic-engbers/types/account/Address';
import { CheckoutData, CheckoutErrorsType, CustomerData, CustomerOrigin, ForbiddenFields } from '@frontastic-engbers/types/engbers-custom';
import { Block, CountrySelect, InputDate, InputRadioGroup, InputText } from '@engbers/components';
import { AddressExtension } from './address-extension';
import styles from '../checkout.module.scss';
type ValidationErrors = {
  salutation: boolean;
  firstName: boolean;
  lastName: boolean;
  streetName: boolean;
  streetNumber: boolean;
  postalCode: boolean;
  city: boolean;
  country: boolean;
  birthdate: boolean;
};
type ForbiddenFieldErrors = {
  lastName: boolean;
  streetName: boolean;
  city: boolean;
};
type StreetNamePostalCodeErrors = {
  streetName: boolean;
  postalCode: boolean;
};
interface IAddress {
  data: CheckoutData;
  updateFormInput: (propName: string, newValue: string | AddressType | CustomerData | boolean) => void;
  labels: {
    [name: string]: string;
  };
  isShipping?: boolean;
  countries?: string[];
  customerOrigin: CustomerOrigin;
  forbiddenFields?: ForbiddenFields;
  setCheckoutErrors: React.Dispatch<React.SetStateAction<CheckoutErrorsType>>;
  loggedIn: boolean;
  readyToValidate?: boolean;
}
export const Address: React.FC<IAddress> = ({
  data,
  updateFormInput,
  labels,
  isShipping,
  countries,
  customerOrigin,
  forbiddenFields,
  setCheckoutErrors,
  loggedIn,
  readyToValidate = false
}) => {
  const projectInfo = getProjectInfo();
  const isEmilio = projectInfo?.projectId === 'ea';
  const {
    updateCart
  } = useCart();
  const {
    formatMessage: formatErrorMessage
  } = useFormat({
    name: 'error'
  });
  const {
    formatMessage
  } = useFormat({
    name: 'common'
  });
  const {
    snippetAddressCityInfo
  } = useSnippets();
  const [errors, setErrors] = useState<Partial<ValidationErrors>>({});
  const [isBirthdateValid, setIsBirthdateValid] = useState<boolean>(false);
  const [forbiddenFieldErrors, setForbiddenFieldErrors] = useState<Partial<ForbiddenFieldErrors>>({});
  const [streetNamePostalCodeErrors, setStreetNamePostalCodeErrors] = useState<Partial<StreetNamePostalCodeErrors>>({});
  const [isMinWidthMedium, setIsMinWidthMedium] = useState<boolean>(false);
  const isMobile = useMediaQuery({
    maxWidth: 1023
  });
  const isMounted = useIsMounted();
  const [isCheckoutSubmitted, setIsCheckoutSubmitted] = useRecoilState(isCheckoutSubmittedState);
  const address = isShipping ? data.shippingAddress : data.billingAddress;
  const updateAddress = (propName: string, newValue: string) => {
    updateFormInput(isShipping ? 'shippingAddress' : 'billingAddress', {
      ...address,
      [propName]: newValue
    });
  };
  const updateCustomerData = (propName: string, newValue: string | boolean) => {
    updateFormInput('customerData', {
      ...data.customerData,
      [propName]: newValue
    });
  };
  const updateShippingAddressCountry = (newValue: string) => {
    updateFormInput('shippingAddress', {
      ...data.shippingAddress,
      country: newValue
    });
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, trimEnd = false) => {
    updateAddress(e.target.name, trimEnd ? e.target.value.trimEnd() : e.target.value);
  };
  const handleCustomerBirthdayChange = value => {
    const key = Object.keys(value)[0];
    updateCustomerData(key, value[key]);
  };
  const onChangeCountry = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const isCountryIreland = e.target.value.toLowerCase() === 'ie';
    const updatedData = mapToCartStructure(data, customerOrigin);
    updatedData.shipping.country = e.target.value;
    if (!isShipping) {
      updatedData.billing.country = e.target.value;
      updateShippingAddressCountry(e.target.value);
    }
    handleInputChange(e);
    validatePostalCodeField(address.postalCode, e.target.value);
    setErrors({
      ...errors,
      postalCode: isCountryIreland ? false : address.postalCode !== '' && !address.postalCode
    });
    await updateCart(updatedData);
  };
  const setAddressCheckoutErrors = (isValid: boolean) => {
    if (!isShipping) {
      setCheckoutErrors(state => ({
        ...state,
        billingAddress: {
          isValid: isValid,
          section: state?.billingAddress.section
        }
      }));
    } else {
      setCheckoutErrors(state => ({
        ...state,
        shippingAddress: {
          isValid: isValid,
          section: state?.shippingAddress.section
        }
      }));
    }
  };
  const validateStreetNameField = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddressCheckoutErrors(validateStreetName(e.target.value));
    setStreetNamePostalCodeErrors(state => ({
      ...state,
      streetName: !validateStreetName(e.target.value)
    }));
  };
  const validatePostalCodeField = (value: string, country: string = null) => {
    const isCountryGermany = country ? country.toLowerCase() === 'de' : address.country.toLowerCase() === 'de';
    if (isCountryGermany) {
      setAddressCheckoutErrors(validatePostalCode(value));
    }
    setStreetNamePostalCodeErrors(state => ({
      ...state,
      postalCode: isCountryGermany ? value !== '' && !validatePostalCode(value) : false
    }));
  };
  const validateForForbiddenFields = (fieldsToValidate: {
    [key: string]: string;
  }) => {
    const forbiddenFieldsList = forbiddenFields.forbiddenFieldList.split('|');
    Object.keys(fieldsToValidate).forEach((attribute: string) => {
      let containsForbiddenFields = false;
      forbiddenFieldsList.forEach(forbiddenField => {
        if (fieldsToValidate[attribute].toString().toLowerCase().includes(forbiddenField.toLowerCase())) {
          containsForbiddenFields = true;
        }
      });
      setAddressCheckoutErrors(!containsForbiddenFields);
      setForbiddenFieldErrors(errors => ({
        ...errors,
        [attribute]: containsForbiddenFields
      }));
    });
  };
  const validateFields = (e?: React.ChangeEvent<HTMLInputElement>) => {
    validateForForbiddenFields({
      [e.target.name]: e.target.value
    });
  };
  const validateAddress = () => {
    const isCountryGermany = address.country.toLowerCase() === 'de';
    const isCountryIreland = address.country.toLowerCase() === 'ie';
    setErrors({
      salutation: !address.salutation,
      firstName: !address.firstName,
      lastName: !address.lastName,
      streetName: !address.streetName,
      streetNumber: !address.streetNumber,
      postalCode: isCountryIreland ? false : !address.postalCode,
      city: !address.city,
      country: !address.country,
      birthdate: !isShipping ? !isBirthdateValid : false
    });
    validateForForbiddenFields({
      streetName: address.streetName,
      lastName: address.lastName,
      city: address.city
    });
    setStreetNamePostalCodeErrors({
      streetName: !validateStreetName(address.streetName),
      postalCode: isCountryGermany ? !validatePostalCode(address.postalCode) : false
    });
  };
  useEffect(() => {
    if (readyToValidate) {
      validateAddress();
    }
  }, [readyToValidate]);
  useEffect(() => {
    if (isCheckoutSubmitted) {
      validateAddress();
      setIsCheckoutSubmitted(false);
    }
  }, [isCheckoutSubmitted]);
  useEffect(() => {
    const filteredForbiddenFieldErrors = Object.keys(forbiddenFieldErrors).filter(key => forbiddenFieldErrors[key])?.length;
    const filteredStreetNamePostalCodeErrors = Object.keys(streetNamePostalCodeErrors).filter(key => streetNamePostalCodeErrors[key])?.length;
    setAddressCheckoutErrors(!filteredForbiddenFieldErrors && !filteredStreetNamePostalCodeErrors);
  }, [forbiddenFieldErrors, streetNamePostalCodeErrors]);
  useEffect(() => {
    if (isMobile !== isMinWidthMedium) {
      setIsMinWidthMedium(isMobile);
    }
  }, [isMobile]);
  useEffect(() => {
    const invalidFields = Object.keys(errors)?.filter(key => errors[key]);
    if (isShipping && !invalidFields.length) {
      updateFormInput('isUnselectedShippingAddress', false);
    }
  }, [errors]);
  return <div className={classnames({
    'mr-4': isMounted && isMobile
  })} data-sentry-component="Address" data-sentry-source-file="index.tsx">
      <Block marginBottom={4} data-sentry-element="Block" data-sentry-source-file="index.tsx">
        <InputRadioGroup onChange={id => {
        updateAddress('salutation', id);
        if (id) {
          setErrors({
            ...errors,
            salutation: false
          });
        }
      }} options={[{
        label: labels.radioMister !== '' ? labels.radioMister : formatMessage({
          id: 'gender.male'
        }),
        id: 'MR'
      }, {
        label: labels.radioMs !== '' ? labels.radioMs : formatMessage({
          id: 'gender.female'
        }),
        id: 'MRS'
      }]} activeId={address.salutation ? address.salutation : undefined} errorMessage={errors.salutation ? formatMessage({
        id: 'salutation'
      }) + formatErrorMessage({
        id: 'mandatory.error'
      }) : undefined} data-sentry-element="InputRadioGroup" data-sentry-source-file="index.tsx" />
      </Block>
      <Block customStyle={getBlockStyle(true, isMinWidthMedium)} marginBottom={4} data-sentry-element="Block" data-sentry-source-file="index.tsx">
        <InputText id={isShipping ? 'delivery_first_name' : 'first_name'} name="firstName" type="text" placeholder={labels.labelFirstName !== '' ? labels.labelFirstName : formatMessage({
        id: 'firstName'
      })} autoComplete="given-name" required errorMessage={errors.firstName ? formatMessage({
        id: 'firstName'
      }) + formatErrorMessage({
        id: 'mandatory.error'
      }) : undefined} value={address.firstName} onChange={handleInputChange} onBlur={() => setErrors({
        ...errors,
        firstName: address.firstName === ''
      })} wrapperCustomStyle={{
        width: '100%'
      }} successValidation hasError={errors.firstName} data-sentry-element="InputText" data-sentry-source-file="index.tsx" />
      </Block>
      <Block customStyle={getBlockStyle(false, isMinWidthMedium)} marginBottom={4} data-sentry-element="Block" data-sentry-source-file="index.tsx">
        <InputText id={isShipping ? 'delivery_last_name' : 'last_name'} name="lastName" type="text" placeholder={labels.labelLastName !== '' ? labels.labelLastName : formatMessage({
        id: 'lastName'
      })} autoComplete="family-name" required value={address.lastName} errorMessage={errors.lastName ? formatMessage({
        id: 'lastName'
      }) + formatErrorMessage({
        id: 'mandatory.error'
      }) : forbiddenFieldErrors.lastName ? forbiddenFields.fieldValidationMessage : undefined} onChange={e => {
        handleInputChange(e);
        validateFields(e);
      }} onBlur={e => {
        setErrors({
          ...errors,
          lastName: address.lastName === ''
        });
        validateFields(e);
      }} wrapperCustomStyle={{
        width: '100%'
      }} successValidation hasError={errors.lastName || forbiddenFieldErrors.lastName} data-sentry-element="InputText" data-sentry-source-file="index.tsx" />
      </Block>
      <Block customStyle={getBlockStyle(true, isMinWidthMedium)} marginBottom={4} data-sentry-element="Block" data-sentry-source-file="index.tsx">
        <InputText id={isShipping ? 'delivery_address_street_name' : 'address_street_name'} name="streetName" type="text" placeholder={labels.labelStreet !== '' ? labels.labelStreet : formatMessage({
        id: 'street.name'
      })} autoComplete="" required value={address.streetName} errorMessage={errors.streetName ? formatMessage({
        id: 'street.name'
      }) + formatErrorMessage({
        id: 'mandatory.error'
      }) : forbiddenFieldErrors.streetName ? forbiddenFields.fieldValidationMessage : streetNamePostalCodeErrors.streetName ? formatErrorMessage({
        id: 'streetNameCharacters',
        defaultMessage: 'Straße darf keine Zahlen enthalten!'
      }) : undefined} onChange={e => {
        handleInputChange(e);
        validateFields(e);
        validateStreetNameField(e);
      }} onBlur={e => {
        handleInputChange(e, true);
        setErrors({
          ...errors,
          streetName: address.streetName === ''
        });
        validateFields(e);
        validateStreetNameField(e);
      }} wrapperCustomStyle={{
        width: '100%'
      }} successValidation hasError={errors.streetName || streetNamePostalCodeErrors.streetName || forbiddenFieldErrors.streetName} data-sentry-element="InputText" data-sentry-source-file="index.tsx" />
      </Block>
      <Block customStyle={getBlockStyle(false, isMinWidthMedium)} marginBottom={4} data-sentry-element="Block" data-sentry-source-file="index.tsx">
        <InputText id={isShipping ? 'delivery_address_street_number' : 'address_street_number'} name="streetNumber" type="text" placeholder={labels.labelHouse !== '' ? labels.labelHouse : formatMessage({
        id: 'street.number'
      })} autoComplete="" required value={address.streetNumber} errorMessage={errors.streetNumber ? formatMessage({
        id: 'street.number'
      }) + formatErrorMessage({
        id: 'mandatory.error'
      }) : undefined} onChange={handleInputChange} onBlur={() => setErrors({
        ...errors,
        streetNumber: address.streetNumber === ''
      })} wrapperCustomStyle={{
        width: '100%'
      }} successValidation hasError={errors.streetNumber} data-sentry-element="InputText" data-sentry-source-file="index.tsx" />
      </Block>
      {isShipping ? <Block marginBottom={4}>
          <InputText id="company" name="company" type="text" placeholder={labels.labelCompany !== '' ? labels.labelCompany : formatMessage({
        id: 'company'
      })} autoComplete="" value={address.company} onChange={handleInputChange} wrapperCustomStyle={{
        width: '100%'
      }} successValidation />
        </Block> : null}
      <Block customStyle={getBlockStyle(true, isMinWidthMedium)} marginBottom={4} data-sentry-element="Block" data-sentry-source-file="index.tsx">
        <InputText id={isShipping ? 'delivery_address_zip' : 'address_zip'} name="postalCode" type="text" placeholder={labels.labelZip !== '' ? labels.labelZip : formatMessage({
        id: 'zipCodeShort'
      })} autoComplete="" required={address.country.toLowerCase() !== 'ie'} value={address.postalCode} errorMessage={errors.postalCode ? formatMessage({
        id: 'zipCodeShort'
      }) + formatErrorMessage({
        id: 'mandatory.error'
      }) : streetNamePostalCodeErrors.postalCode ? formatErrorMessage({
        id: 'postalCodeLength',
        defaultMessage: 'PLZ muss in Deutschland 5 Zeigen lang sein'
      }) : undefined} onChange={e => {
        handleInputChange(e);
        validatePostalCodeField(e.target.value);
      }} onBlur={e => {
        const isCountryIreland = address.country.toLowerCase() === 'ie';
        setErrors({
          ...errors,
          postalCode: address.postalCode === '' && !isCountryIreland
        });
        validatePostalCodeField(e.target.value);
      }} wrapperCustomStyle={{
        width: '100%'
      }} successValidation={address.postalCode !== ''} hasError={errors.postalCode || streetNamePostalCodeErrors.postalCode} data-sentry-element="InputText" data-sentry-source-file="index.tsx" />
      </Block>
      <Block customStyle={getBlockStyle(false, isMinWidthMedium)} marginBottom={4} data-sentry-element="Block" data-sentry-source-file="index.tsx">
        <InputText id={isShipping ? 'delivery_address_city' : 'address_city'} name="city" type="text" placeholder={labels.labelCity !== '' ? labels.labelCity : formatMessage({
        id: 'city'
      })} autoComplete="" required value={address.city} errorMessage={errors.city ? formatMessage({
        id: 'place'
      }) + formatErrorMessage({
        id: 'mandatory.error'
      }) : forbiddenFieldErrors.city ? forbiddenFields.fieldValidationMessage : undefined} onChange={e => {
        handleInputChange(e);
        validateFields(e);
      }} onBlur={e => {
        setErrors({
          ...errors,
          city: address.city === ''
        });
        validateFields(e);
      }} wrapperCustomStyle={{
        width: '100%'
      }} successValidation hasError={errors.city || forbiddenFieldErrors.city} {...!isShipping && {
        infoText: snippetAddressCityInfo,
        useInfoModal: true,
        infoModalContentClassName: styles.infoModalContent,
        infoModalHasOkayButton: false,
        infoModalIsLarge: true,
        infoModalCanCloseByBackdropClick: true,
        infoIconSize: 18,
        infoIconPosition: 'outer'
      }} data-sentry-element="InputText" data-sentry-source-file="index.tsx" />
      </Block>
      <Block customStyle={customerOrigin.isGuest || customerOrigin.isNew ? getBlockStyle(true, isMinWidthMedium) : null} marginBottom={4} data-sentry-element="Block" data-sentry-source-file="index.tsx">
        <CountrySelect id={isShipping ? 'delivery_address_country' : 'address_country'} name="country" placeholder={labels.labelCountry !== '' ? labels.labelCountry : formatMessage({
        id: 'country'
      })} autoComplete="" required value={address.country} errorMessage={errors.country ? formatMessage({
        id: 'country'
      }) + formatErrorMessage({
        id: 'mandatory.error'
      }) : undefined} onChange={e => onChangeCountry(e)} wrapperCustomStyle={{
        width: '100%'
      }} successValidation allowed={countries} showDropdownArrow={isEmilio} data-sentry-element="CountrySelect" data-sentry-source-file="index.tsx" />
      </Block>
      {!loggedIn && !isShipping ? <Block customStyle={getBlockStyle(false, isMinWidthMedium)} marginBottom={4}>
          <InputDate birthdayDay={data.customerData.birthdayDay} birthdayMonth={data.customerData.birthdayMonth} birthdayYear={data.customerData.birthdayYear} label={labels.labelBirthdate ? labels.labelBirthdate : formatMessage({
        id: 'birthDate'
      })} required onChange={handleCustomerBirthdayChange} isFormatValid={valid => setIsBirthdateValid(valid)} stillHasError={errors?.birthdate} />
        </Block> : null}

      {!loggedIn && <AddressExtension data={data} labels={labels} updateFormInput={updateFormInput} customerOrigin={customerOrigin} isMinWidthMedium={isMinWidthMedium} setCheckoutErrors={setCheckoutErrors} />}
    </div>;
};